/*============================================================================*\
   Global SCSS configuration
\*============================================================================*/
/*============================================================================*\
   Assets
\*============================================================================*/
/*============================================================================*\
   Typography
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/*============================================================================*\
   Layers
\*============================================================================*/
/*============================================================================*\
   Main entry point
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Enable of disable the helper classes */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/*============================================================================*\
   Function helper
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @return {string}                  A media query expression
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/*============================================================================*\
   Color helpers
\*============================================================================*/
/*============================================================================*\
   SVG Color helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/**
 * Definition of the space rules
 */
/*============================================================================*\
   Helper function
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Space gutter
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-x2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-x4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/*============================================================================*\
   Helper mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 * @source http://cloud.meta.fr/8fdbea50b775
 */
/*============================================================================*\
   Helper classes
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/**
 * A map to define all font-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * The `fz($font-size, $unit)` and the `lh($font-size)` functions below can be
 * used to get easily one of the two values.
 *
 * @type {Map}
 */
/*============================================================================*\
   Font-sizes helper function
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
/**
 * A function helper to get the computed line-height of the given font-size
 * @param  {string} $font-size The name of the font-size
 * @return {string}            The corresponding line-height
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 * @param  {string} $font-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/*============================================================================*\
   Fontaces declarations
\*============================================================================*/
/*============================================================================*\
   Responsive type mixin
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-font  The minimum font-size
 * @param  {integer} $max-font  The maximum font-size
 * @return {void}
 */
/*============================================================================*\
   Antialiasing mixin
\*============================================================================*/
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type font-size helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement helpers
\*============================================================================*/
/*============================================================================*\
   Type font families helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/*============================================================================*\
   Type spacing
\*============================================================================*/
/*============================================================================*\
   Type transform
\*============================================================================*/
/*============================================================================*\
   Type font defaults
\*============================================================================*/
/*============================================================================*\
   UI spacings
\*============================================================================*/
.box {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48em) {
.box {
      padding: 1.5rem;
}
}
@media (min-width: 48em) {
.box {
      width: auto;
}
}
.box__inner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
}
@media (min-width: 48em) {
.box__inner {
      width: auto;
}
}

/*==========================================================================*\
   Logo element
\*==========================================================================*/
.box__logo {
  display: inline-block;
}
.box__logo--enter,
.box__logo--leave-to {
  opacity: 0;
}
.box__logo--enter-active,
.box__logo--leave-active {
  transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.box__logo--enter-active {
  transition-duration: 3s;
  transition-delay: 0.8s;
}

/*==========================================================================*\
   Introduction element
\*==========================================================================*/
.box__introduction span {
  display: inline-block;
  opacity: 0.99;
}
.box__introduction--enter,
.box__introduction--leave-to {
  opacity: 0;
}
.box__introduction--enter-active {
  transition: opacity 4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.box__introduction--leave-active {
  transition: opacity 3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*==========================================================================*\
   Title element
  .box__title {}
\*==========================================================================*/
.box__title--enter .box__letter,
.box__title--leave-to .box__letter {
  opacity: 0;
}
.box__title--enter-active,
.box__title--enter-active .box__letter,
.box__title--leave-active,
.box__title--leave-active .box__letter {
  transition: all 3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-delay: 0.4s;
}
.box__title--enter-active .box__letter:nth-child(1n+1),
.box__title--leave-active .box__letter:nth-child(1n+1) {
  transition-duration: 2.5s;
}
.box__title--enter-active .box__letter:nth-child(2n+1),
.box__title--leave-active .box__letter:nth-child(2n+1) {
  transition-duration: 3.5s;
}
.box__title--enter-active .box__letter:nth-child(3n+1),
.box__title--leave-active .box__letter:nth-child(3n+1) {
  transition-duration: 2.5s;
}
.box__title--enter-active .box__letter:nth-child(4n+1),
.box__title--leave-active .box__letter:nth-child(4n+1) {
  transition-duration: 3.5s;
}
.box__title--enter-active .box__letter:nth-child(5n+1),
.box__title--leave-active .box__letter:nth-child(5n+1) {
  transition-duration: 1.5s;
}
.box__title--enter-active .box__letter:nth-child(6n+1),
.box__title--leave-active .box__letter:nth-child(6n+1) {
  transition-duration: 1.5s;
}
.box__title--enter-active .box__letter:nth-child(7n+1),
.box__title--leave-active .box__letter:nth-child(7n+1) {
  transition-duration: 1.5s;
}
.box__title--enter-active .box__letter:nth-child(8n+1),
.box__title--leave-active .box__letter:nth-child(8n+1) {
  transition-duration: 1.5s;
}
.box__title--enter-active .box__letter:nth-child(9n+1),
.box__title--leave-active .box__letter:nth-child(9n+1) {
  transition-duration: 3.5s;
}
.box__title--enter-active .box__letter:nth-child(10n+1),
.box__title--leave-active .box__letter:nth-child(10n+1) {
  transition-duration: 2.5s;
}
.box__title--leave-active,
.box__title--leave-active .box__letter {
  transition: opacity 1.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.box__title--leave-active .box__letter:nth-child(1n+1) {
  transition-duration: 1.35441s;
}
.box__title--leave-active .box__letter:nth-child(2n+1) {
  transition-duration: 1.26492s;
}
.box__title--leave-active .box__letter:nth-child(3n+1) {
  transition-duration: 1.14335s;
}
.box__title--leave-active .box__letter:nth-child(4n+1) {
  transition-duration: 1.29021s;
}
.box__title--leave-active .box__letter:nth-child(5n+1) {
  transition-duration: 1.08658s;
}
.box__title--leave-active .box__letter:nth-child(6n+1) {
  transition-duration: 1.31218s;
}
.box__title--leave-active .box__letter:nth-child(7n+1) {
  transition-duration: 1.31026s;
}
.box__title--leave-active .box__letter:nth-child(8n+1) {
  transition-duration: 1.07511s;
}
.box__title--leave-active .box__letter:nth-child(9n+1) {
  transition-duration: 1.21723s;
}
.box__title--leave-active .box__letter:nth-child(10n+1) {
  transition-duration: 1.08938s;
}

/*==========================================================================*\
   Word element
\*==========================================================================*/
.box__word {
  display: inline-block;
  white-space: nowrap;
}

/*==========================================================================*\
   Letter element
\*==========================================================================*/
.box__letter {
  display: inline-block;
  opacity: 0.99;
}
